import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import Questionarios from './Questionarios';
import Resultados from './Resultados';
import Perguntas from './Perguntas';
import VisualizarQuestionario from './VisualizarQuestionario';
import EditarQuestionario from './EditarQuestionario';
import ResponderQuestionario from './ResponderQuestionario';
import Login from './Login';
import './App.css'; // Importar o arquivo CSS para estilização

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // <- controle de carregamento
  const location = useLocation();

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
    setLoading(false); // <- libera render após checar
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  if (loading) return <p>Carregando...</p>; // <- segura até verificar login

  return (
    <div className="app">
      <header>
    

        {/* Exibe navbar apenas se logado e fora da tela de resposta */}
    
        {isAuthenticated && !location.pathname.startsWith("/responder-questionario") && (
          <><div className="logo">
            <img src="/logo.png" alt="Logo" />
          </div><nav className="navbar">
              <ul>
                <li><Link to="/">Questionário</Link></li>
                <li><Link to="/resultados">Resultados</Link></li>
                <li><Link to="/responder-questionario/IH9g4zQNmel92RWaXWj2">Responder Questionário</Link></li>
              </ul>
            </nav></>
        )}
      </header>
      <main>
        <Routes>
          {/* Rota pública */}
          <Route path="/responder-questionario/:id" element={<ResponderQuestionario />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          {/* Rotas protegidas */}
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Questionarios />} />
              <Route path="/resultados" element={<Resultados />} />
              <Route path="/criar-questionario" element={<Perguntas />} />
              <Route path="/perguntas" element={<Perguntas />} />
              <Route path="/questionario/:id" element={<VisualizarQuestionario />} />
              <Route path="/editar-questionario/:id" element={<EditarQuestionario />} />
            </>
          ) : (
            <>
              {/* qualquer outra rota cai aqui */}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </main>
    </div>
  );
};


// Wrapping App inside Router to use useLocation
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
