import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';

const EditarQuestionario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questionario, setQuestionario] = useState(null);

  useEffect(() => {
    const fetchQuestionario = async () => {
      const docRef = doc(db, 'questionarios', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data(); // Obtém os dados do Firestore
        
        if (data.questions && Array.isArray(data.questions)) {
          setQuestionario(data); // Atualiza o estado apenas se `questions` existir
        } else {
          alert('O documento não contém questions');
          console.warn("O documento não contém 'questions'.");
        }
      } else {
        console.log("O documento não foi encontrado!");
      }
    };

    fetchQuestionario();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestionario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleQuestionChange = (qId, field, value) => {
    setQuestionario((prev) => ({
      ...prev,
      questions: prev.questions.map((q, index) =>
        index === qId ? { ...q, [field]: value } : q
      ),
    }));
  };

  const handleOptionChange = (qId, index, value) => {
    setQuestionario((prev) => ({
      ...prev,
      questions: prev.questions.map((q, qIndex) =>
        qIndex === qId
          ? {
              ...q,
              options: q.options.map((opt, oIndex) =>
                oIndex === index ? value : opt
              ),
            }
          : q
      ),
    }));
  };

  const handleRequiredChange = (qId, value) => {
    setQuestionario((prev) => ({
      ...prev,
      questions: prev.questions.map((q, index) =>
        index === qId ? { ...q, required: value } : q
      ),
    }));
  };

  const addQuestion = () => {
    setQuestionario((prev) => ({
      ...prev,
      questions: [...prev.questions, { question: '', type: 'text', options: [''], required: false }],
    }));
  };

  const removeQuestion = (qIndex) => {
    setQuestionario((prev) => ({
      ...prev,
      questions: prev.questions.filter((_, index) => index !== qIndex),
    }));
  };

  const addOption = (qIndex) => {
    setQuestionario((prev) => ({
      ...prev,
      questions: prev.questions.map((q, index) =>
        index === qIndex ? { ...q, options: [...q.options, ''] } : q
      ),
    }));
  };

  const removeOption = (qIndex, oIndex) => {
    setQuestionario((prev) => ({
      ...prev,
      questions: prev.questions.map((q, index) =>
        index === qIndex
          ? { ...q, options: q.options.filter((_, optIndex) => optIndex !== oIndex) }
          : q
      ),
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'questionarios', id), questionario);
      navigate('/');
    } catch (error) {
      console.error('Erro ao atualizar documento: ', error);
    }
  };

  const duplicateQuestion = (id) => {
    const questionToDuplicate = questionario.questions.find(q => q.id === id);
    if (questionToDuplicate) {
      const newId = questionario.questions[questionario.questions.length - 1].id + 1;
      const duplicatedQuestion = { ...questionToDuplicate, id: newId };
      setQuestionario((prevQuestionario) => ({
        ...prevQuestionario,
        questions: [...prevQuestionario.questions, duplicatedQuestion],
      }));
    }
  };

  if (!questionario) {
    return <p>Carregando questionário...</p>;
  }

  return (
    <div className="container">
      <h1>Editar Questionário</h1>
      <form onSubmit={handleSave}>
        <div className="question-block">
          <label>
            Nome do Questionário:
            <input
              type="text"
              name="nome"
              value={questionario.nome}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        {questionario.questions.map((q, qIndex) => (
          <div key={qIndex} className="question-block">
            <label>
              Pergunta:
              <input
                type="text"
                value={q.question}
                onChange={(e) => handleQuestionChange(qIndex, 'question', e.target.value)}
                required={q.required}
              />
            </label>
            <label>
              Tipo:
              <select
                value={q.type}
                onChange={(e) => handleQuestionChange(qIndex, 'type', e.target.value)}
              >
                <option value="text">Texto</option>
                <option value="radio">Escolha Múltipla</option>
              </select>
            </label>
            {q.type === 'text' ? (
              <input type="text" disabled placeholder="Resposta do texto" />
            ) : (
              <div>
                {q.options.map((option, oIndex) => (
                  <div key={oIndex} className="option-row">
                    <input
                      type="text"
                      value={option}
                      onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
                    />
                    <button type="button" onClick={() => removeOption(qIndex, oIndex)}>Remover Opção</button>
                  </div>
                ))}
                <button type="button" style={{ backgroundColor: 'blue', color: 'white' }} onClick={() => addOption(qIndex)}>Adicionar Opção</button>
              </div>
            )}
            <label style={{ marginTop: '5px', marginBottom: '5px' }}>
              Pergunta Obrigatória?
              <input
                type="checkbox"
                checked={q.required}
                onChange={(e) => handleRequiredChange(qIndex, e.target.checked)}
              /> Sim
            </label>
            <button type="button" onClick={() => removeQuestion(qIndex)}>Remover Pergunta</button>
            <button type="button" onClick={() => duplicateQuestion(q.id)} style={{ backgroundColor: 'green', color: 'white' }} >
              Duplicar Pergunta
            </button>
          </div>
        ))}
        <button type="button" style={{ backgroundColor: 'green', color: 'white' }} onClick={addQuestion}>Adicionar Pergunta</button>
        <button type="submit">Salvar Alterações</button>
      </form>
      <button onClick={() => navigate('/')}>Voltar</button>
    </div>
  );
};

export default EditarQuestionario;
