import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc, addDoc, collection,query,getDocs,where } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';

const ResponderQuestionario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questionario, setQuestionario] = useState(null);
  const [respostas, setRespostas] = useState([]);
  const [jaRespondeu, setJaRespondeu] = useState(false);

   useEffect(() => {
    const fetchQuestionario = async () => {
      const ip = await getUserIP();

      // Verifica se já respondeu
      const resultsRef = collection(db, 'results');
      const q = query(resultsRef, where('ip', '==', ip));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setJaRespondeu(true);
        return;
      }

      // Carrega questionário
      const docRef = doc(db, 'questionarios', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionario(docSnap.data());
        setRespostas(docSnap.data().questions.map(() => ""));
      } else {
        console.log('Questionário não encontrado.');
      }
    };

    fetchQuestionario();
  }, [id]);

  const handleChange = (index, value) => {
    const newRespostas = [...respostas];
    newRespostas[index] = value;
    setRespostas(newRespostas);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const ip = await getUserIP();
      const resultData = {
        answers: [
          {
            ativo: true,
            id : 1,
            nome: 'Pesquisa final',
            questions: questionario.questions.map((q, index) => ({
              question: q.question,
              options: [ respostas[index] ?? "" ] , // Agora salva como string diretamente
            })),
          },
        ],
        timestamp: new Date().toISOString(),
        ip,
        location: {
          latitude : 0,
          longitute : 0
        },
      };
  
      // Salva os dados no Firestore
      await addDoc(collection(db, 'results'), resultData);
  
      // Exibe a mensagem de sucesso
      alert('Obrigado por participar da pesquisa!');
  
      // Marca que já respondeu no localStorage
    localStorage.setItem(`respondeu_${id}`, 'true');


      // Aguarda um curto período antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 500);
  
    } catch (error) {
      console.error('Erro ao salvar respostas: ', error);
      alert('Ocorreu um erro ao salvar o questionário. Tente novamente.');
    }
  };

  const getUserIP = async () => {
    const res = await fetch('https://api.ipify.org?format=json');
    const data = await res.json();
    return data.ip;
  };

  if (jaRespondeu) {
    return (
      <div className="mensagem-ja-respondeu">
        <h2>Você já respondeu esse questionário. Obrigado! </h2>
      </div>
    );
  }

  if (!questionario) {
    return <p>Carregando questionário...</p>;
  }

  return (
    <div className="container-responder">
    <img className="tabulaquest" src={`${process.env.PUBLIC_URL}/tabulaquest.jpg`} alt="Descrição da imagem" />
      <h1>{questionario.name}</h1>
      <h3>Grato por responder o questionário abaixo  </h3>
      <form onSubmit={handleSubmit}>
        {questionario.questions.map((q, index) => (
          <div key={index} className="question-block">
            <p>{q.question}</p>
            {q.type === 'text' ? (
              <input
                type="text"
                value={respostas[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                required
              />
            ) : (
              <div className="radio-group">
                {q.options.map((option, oIndex) => (
                  <div key={oIndex} className="radio-option">
                    <input
                      type="radio"
                      id={`q${index}_o${oIndex}`}
                      name={`q${index}`}
                      value={option}
                      checked={respostas[index] === option}
                      onChange={(e) => handleChange(index, option)}
                      required
                      className="radio-custom"
                    />
                    <label htmlFor={`q${index}_o${oIndex}`}>{option}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <button type="submit">Enviar Respostas</button>
      </form>
    </div>
  );
};

export default ResponderQuestionario;
